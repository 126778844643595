import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './dialogues.css';
import deleteIcon from '../../assets/images/delete_icon.png';

const Dialogues = () => {
  const { source } = useParams();
  const [databases, setDatabases] = useState([]);
  const [messages, setMessages] = useState([]);
  const [selectedDatabase, setSelectedDatabase] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [deleting, setDeleting] = useState(null);

  const fetchDatabases = async () => {
    const username = localStorage.getItem('username');
    if (!username) {
      console.error('No username found in localStorage');
      return;
    }

    try {
      const response = await axios.get(`https://heliosai.ru/api/databases`, {
        params: { username, source }
      });
      setDatabases(response.data.databases);
    } catch (error) {
      console.error('Error fetching databases:', error);
    }
  };

  useEffect(() => {
    fetchDatabases();
  }, [source]);

  const fetchMessages = async (database) => {
    const username = localStorage.getItem('username');
    if (!username) {
      console.error('No username found in localStorage');
      return;
    }

    try {
      const response = await axios.get(`https://heliosai.ru/api/dialogue_messages`, {
        params: { dbFile: database, username, source }
      });
      const messagesWithTime = response.data.messages.map(msg => ({
        ...msg,
        timestamp: convertToMoscowTime(msg.timestamp)
      }));
      setMessages(messagesWithTime);
      setSelectedDatabase(database);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const convertToMoscowTime = (timestamp) => {
    const date = new Date(timestamp);
    const options = { timeZone: 'Europe/Moscow', hour: '2-digit', minute: '2-digit' };
    return date.toLocaleTimeString('ru-RU', options);
  };

  const handleDelete = async (database) => {
    setDeleting(database);
    setTimeout(async () => {
      const username = localStorage.getItem('username');
      if (!username) {
        console.error('No username found in localStorage');
        return;
      }

      try {
        await axios.delete(`https://heliosai.ru/api/delete_dialogue`, {
          params: { dbFile: database, username, source }
        });
        await fetchDatabases();
        setDeleting(null);
        if (selectedDatabase === database) {
          setMessages([]);
          setSelectedDatabase(null);
        }
      } catch (error) {
        console.error('Error deleting database:', error);
      }
    }, 500);
  };

  const handleSendMessage = async (event) => {
    event.preventDefault();
    const username = localStorage.getItem('username');
    if (!username || !selectedDatabase) {
      console.error('No username or database selected');
      return;
    }

    try {
      const response = await axios.post('https://heliosai.ru/api/dataD', {
        role: 'assistant',
        query: newMessage,
        ip: selectedDatabase,
        siteKey: username,
        source: source
      });

      if (response.data && response.data.status === 'success') {
        setMessages([...messages, { author: 'assistant', text: newMessage, timestamp: convertToMoscowTime(new Date().toISOString()) }]);
        setNewMessage('');
      } else {
        console.error('Error sending message:', response.data ? response.data.message : 'No response data');
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  return (
    <div className="dialogues-container">
      <div className="dialogues-list">
        <h2>Выберите диалог</h2>
        {databases.map((database, index) => (
          <div
            key={index}
            className={`database-item ${selectedDatabase === database ? 'selected' : ''} ${deleting === database ? 'slide-out' : ''}`}
            onClick={() => fetchMessages(database)}
          >
            <span className="dialogue-name">{database}</span>
            <img
              src={deleteIcon}
              alt="Delete"
              className="delete-button"
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(database);
              }}
            />
          </div>
        ))}
      </div>
      <div className="messages-container">
        <h2>Диалог</h2>
        <div className="messages">
          {messages.map((msg, index) => (
            <div key={index} className={`message ${msg.author}`}>
              <p><strong>{msg.author}</strong>: {msg.text}</p>
              <span className="timestamp">{msg.timestamp}</span>
            </div>
          ))}
        </div>
        {selectedDatabase && (
          <form onSubmit={handleSendMessage} className="send-message-form">
            <input
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Введите сообщение"
              required
            />
            <button type="submit">Отправить</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Dialogues;