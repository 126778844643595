// src/components/VerifyEmail.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './verify.css';

const VerifyEmail = () => {
  const location = useLocation();
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      // Получаем параметр token из URL
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get('token');

      if (!token) {
        setMessage('Отсутствует токен для подтверждения');
        return;
      }

      try {
        const response = await axios.get(`https://heliosai.ru/api/verify-email?token=${token}`);
        setMessage(response.data.message || 'Email успешно подтвержден');

        // Перенаправление на страницу входа через 3 секунды
        setTimeout(() => navigate('/login'), 3000);
      } catch (error) {
        setMessage('Ошибка при подтверждении: ' + (error.response?.data?.detail || 'Неизвестная ошибка'));
      }
    };

    verifyEmail();
  }, [location, navigate]);

  return (
    <div className="verify-email-container">
      <h2>{message}</h2>
    </div>
  );
};

export default VerifyEmail;