// src/comps/adminPanel/AdminPanel.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './adminPanel.css';

function AdminPanel() {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, [token, navigate]);

  return (
    <div className="admin-panel">
      <h1>Админ Панель</h1>
      <DataTable />
      <AsstFoldersView />
    </div>
  );
}

function DataTable() {
  const [entries, setEntries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem('token');

  useEffect(() => {
    axios.get('https://heliosai.ru/api/data_entries', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        setEntries(response.data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error(error);
        // Обработка ошибки
      });
  }, [token]);

  const handleFieldChange = (login, fieldName, newValue) => {
    setEntries(prevEntries => prevEntries.map(entry => {
      if (entry.login === login) {
        return { ...entry, [fieldName]: newValue };
      }
      return entry;
    }));
  };

  const handleFieldBlur = (login, fieldName, value) => {
    axios.post('https://heliosai.ru/api/data_entries/update_field', {
      login,
      field_name: fieldName,
      value
    }, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    })
    .then(response => {
      // Обработка успешного ответа
    })
    .catch(error => {
      console.error(error);
      // Обработка ошибки
    });
  };

  return (
    <div className="data-table-container">
      <h2>Таблица данных</h2>
      {isLoading ? (
        <p>Загрузка...</p>
      ) : (
        <table className="data-table">
          <thead>
            <tr>
              <th>Login</th>
              <th>Domen</th>
              <th>API Key</th>
              <th>Assistant Key</th>
              <th>Instagram Login</th>
              <th>Instagram Password</th>
              <th>VK ID</th>
              <th>WhatsApp ID</th>
              <th>Telegram Number</th>
            </tr>
          </thead>
          <tbody>
            {entries.map(entry => (
              <tr key={entry.login}>
                {['login', 'domen', 'api_key', 'assistant_key', 'instagram_login', 'instagram_password', 'vk_id', 'whatsapp_id', 'telegram_token'].map(field => (
                  <td key={field}>
                    <input
                      type="text"
                      value={entry[field] || ''}
                      onChange={e => handleFieldChange(entry.login, field, e.target.value)}
                      onBlur={e => handleFieldBlur(entry.login, field, e.target.value)}
                      disabled={field === 'login'} // Поле 'login' недоступно для редактирования
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}


function AsstFoldersView() {
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [databases, setDatabases] = useState([]);
  const [selectedDatabase, setSelectedDatabase] = useState(null);
  const [messages, setMessages] = useState([]);
  const token = localStorage.getItem('token');

  useEffect(() => {
    axios.get('https://heliosai.ru/api/asst_folders', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        setFolders(response.data.folders);
      })
      .catch(error => {
        console.error(error);
      });
  }, [token]);

  const handleFolderClick = (folder) => {
    setSelectedFolder(folder);
    setSelectedDatabase(null);
    setMessages([]);
    axios.get(`https://heliosai.ru/api/asst_folders/${folder}/databases`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        setDatabases(response.data.databases);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleDatabaseClick = (dbfile) => {
    setSelectedDatabase(dbfile);
    axios.get(`https://heliosai.ru/api/asst_folders/${selectedFolder}/databases/${dbfile}/messages`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        setMessages(response.data.messages);
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <div className="asst-folders-view">
      <div className="folders-list">
        <h2>Папки</h2>
        <ul>
          {folders.map(folder => (
            <li key={folder} onClick={() => handleFolderClick(folder)} className={folder === selectedFolder ? 'active' : ''}>
              {folder}
            </li>
          ))}
        </ul>
      </div>
      <div className="databases-list">
        {selectedFolder && (
          <>
            <h2>Базы данных</h2>
            <ul>
              {databases.map(dbfile => (
                <li key={dbfile} onClick={() => handleDatabaseClick(dbfile)} className={dbfile === selectedDatabase ? 'active' : ''}>
                  {dbfile}
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      <div className="messages-view">
        {selectedDatabase && (
          <>
            <h2>Сообщения из {selectedDatabase}</h2>
            <ul>
              {messages.map((msg, index) => (
                <li key={index}>
                  <strong>{msg.author}:</strong> {msg.text}
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
}

export default AdminPanel;
